//
// Header
//

// General mode
.app-header-menu {
    .menu {
        // General
        .menu-item {
            @include menu-link-default-state( 
                $title-color: white, 
                $icon-color: var(--#{$prefix}gray-500), 
                $bullet-color: var(--#{$prefix}gray-500), 
                $arrow-color: var(--#{$prefix}gray-500),
                $bg-color: null
            );
            
            @include menu-link-hover-state( 
                $title-color: var(--#{$prefix}primary), 
                $icon-color: var(--#{$prefix}primary), 
                $bullet-color: var(--#{$prefix}primary), 
                $arrow-color: var(--#{$prefix}primary), 
                $bg-color: null
            );
            
            @include menu-link-show-state( 
                $title-color: var(--#{$prefix}primary), 
                $icon-color: var(--#{$prefix}primary), 
                $bullet-color: var(--#{$prefix}primary), 
                $arrow-color: var(--#{$prefix}primary), 
                $bg-color: null
            );

            @include menu-link-here-state( 
                $title-color: var(--#{$prefix}primary), 
                $icon-color: var(--#{$prefix}primary),
                $bullet-color: var(--#{$prefix}primary), 
                $arrow-color: var(--#{$prefix}primary),
                $bg-color: null
            );

            @include menu-link-active-state( 
                $title-color: var(--#{$prefix}primary), 
                $icon-color: var(--#{$prefix}primary), 
                $bullet-color: var(--#{$prefix}primary), 
                $arrow-color: var(--#{$prefix}primary), 
                $bg-color: var(--#{$prefix}gray-100)
            );                
        }

        // Root
        > .menu-item {
            > .menu-link {
                > .menu-title {
                    font-weight: $font-weight-bold;
                    font-size: 1.1rem;
                }
            }    
        }
    }

    .menu-extended {
        --#{$prefix}menu-link-bg-color-active: rgba(var(--#{$prefix}gray-100-rgb), 0.7);
        --#{$prefix}menu-link-bg-color-hover: rgba(var(--#{$prefix}gray-100-rgb), 0.7);
    
        .menu-custom-icon {
            background-color: var(--#{$prefix}gray-100);    
        }

        .menu-link {
            &.active,
            &:hover {
                .menu-custom-icon {
                    background-color: var(--#{$prefix}gray-200);    
                }
            }    
        }
    }
}

// Desktop mode
@include media-breakpoint-up(lg) {
    .app-header-menu {
        .menu {
            // Menu root item
            > .menu-item {
                margin-right: 0.5rem;

                @include menu-link-here-state( 
                    $title-color: var(--#{$prefix}primary), 
                    $icon-color: var(--#{$prefix}primary), 
                    $bullet-color: var(--#{$prefix}primary), 
                    $arrow-color: var(--#{$prefix}primary), 
                    $bg-color: var(--#{$prefix}gray-100)
                );
                
                > .menu-link {
                    padding-top: 0.675rem;
                    padding-bottom: 0.675rem;    
                    font-weight: $font-weight-semibold;                   
                }
            }
        }
    }    

    [data-kt-app-toolbar-fixed="true"] {
        .app-header {
            background-color: var(--#{$prefix}app-header-minimize-bg-color);
        }    
    }

    // Modal open fix
    [data-kt-app-header-fixed="true"].modal-open {
        .app-header {
            padding-right: $body-scrollbar-width !important;    
        }    
    }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	.app-header { 
        background-color: var(--#{$prefix}app-header-minimize-bg-color);
        border-bottom: 1px solid var(--#{$prefix}border-color) !important;

		.page-title {
            display: none !important;    
        }
	}    
}