
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$demo1-primary: mat.define-palette(mat.$indigo-palette);
$demo1-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$demo1-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$demo1-theme: mat.define-light-theme((
  color: (
    primary: $demo1-primary,
    accent: $demo1-accent,
    warn: $demo1-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($demo1-theme);

/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style.scss";
// Replace above style with this css file to enable RTL styles
// @import "./assets/css/style.rtl";
@import './assets/sass/plugins.scss';
// @import "./assets/css/style.rtl.css";
@import "./assets/sass/style.angular.scss";


@import "~@ng-select/ng-select/themes/default.theme.css";

:root {
    --dangerFont: #8e193a;
    --dangerHeaderCard: #FCE3E6;
    --defaultHeaderCard: #F7F7F7;
    --primary: #0078FF;
}
html, body { height: 100%; }
body { margin: 0; font-family: Poppins, "Helvetica Neue", sans-serif; }


// start css global ng-select
.ng-select .ng-select-container  {            
  background-color: #f7f7f7;
  border-radius: 5px;
  height: 43px !important;
  border: none;
}

ng-select.ng-invalid.ng-select-opened .ng-select-container {            
  background-color: rgb(238, 237, 241);
}

ng-select.ng-valid.ng-select-opened .ng-select-container {            
  background-color: rgb(238, 237, 241);
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 10px;
}

.ng-select div, .ng-select input, .ng-select span {
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: var(--bs-gray-700);
}

